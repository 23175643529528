import {
  Avatar,
  Box,
  Card,
  CardHeader,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import Label from "./Label";

import { Bar, BarChart, ResponsiveContainer } from "recharts";

function AnalyticsCard({
  title,
  AvatarColor,
  bg,
  ChartColor,
  number,
  icon,
  allSessionData,
}: any) {
  const { t }: { t: any } = useTranslation();

  const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
            background-color: ${bg};
            color: ${AvatarColor};
            width: ${theme.spacing(6)};
            height: ${theme.spacing(6)};
            margin-top: ${theme.spacing(1)};
      `
  );

  const data = [
    {
      name: "Jan",
      TotalP: 4000,
    },
    {
      name: "Feb",
      TotalP: 3000,
    },
    {
      name: "March",
      TotalP: 4000,
    },
    {
      name: "April",
      TotalP: 1500,
    },
    {
      name: "May",
      TotalP: 5000,
    },
    {
      name: "July",
      TotalP: 4000,
    },
    {
      name: "Aug",
      TotalP: 3000,
    },
    {
      name: "Sept",
      TotalP: 2500,
    },
    {
      name: "Oct",
      TotalP: 2000,
    },
  ];

  const getArrowIconAndLabel = (value: string | undefined) => {
    if (!value || typeof value !== "string") {
      return (
        <Label color="info">
          <b>-</b>
        </Label>
      );
    }

    if (value === "0%" || value === "0.00%") {
      return (
        <Label color="info">
          <b>{value}</b>
        </Label>
      );
    }

    const isPositive = value?.startsWith("+");
    const icon = isPositive ? (
      <ArrowUpwardTwoToneIcon fontSize="small" />
    ) : (
      <ArrowDownwardTwoToneIcon fontSize="small" />
    );
    const labelColor = isPositive ? "success" : "error";

    return (
      <Label color={labelColor}>
        {icon}
        <b>{value}</b>
      </Label>
    );
  };

  return (
    <Card
      sx={{
        px: 1,
        pt: 1,
        height: "100%",
      }}
    >
      <CardHeader
        sx={{
          textAlign: "right",
          pb: 0,
          pl: 1,
        }}
        avatar={<AvatarPrimary>{icon}</AvatarPrimary>}
        action={
          <>
            {getArrowIconAndLabel(allSessionData)}
            <Typography
              align="right"
              variant="subtitle1"
              color="text.secondary"
            >
              {t("since last month")}
            </Typography>
          </>
        }
      />

      <Typography
        sx={{
          pt: 7,
          pl: 1,
          fontFamily: "Inter_regular",
          fontSize: "16px",
          opacity: "0.85",
        }}
      >
        {title}
      </Typography>

      <Box
        height="45%"
        width="98%"
        sx={{
          pl: 1,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          width="98%"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h1">{number}</Typography>
          </Box>
          <ResponsiveContainer width="60%" height="85%">
            <BarChart
              data={data}
              barGap={3}
              barSize={7}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 2,
              }}
            >
              <Bar dataKey="TotalP" fill={ChartColor} radius={10} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Card>
  );
}

export default AnalyticsCard;
