import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";

import EventIcon from "@mui/icons-material/Event";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { styled } from "@mui/system";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  getClinicianProfile,
  updateClinicianSession,
  updateSession,
} from "../../../../api/services/api";
import { icons } from "../../../../assets";
import Loader from "../../../../components/Loader";

interface SessionProps {
  patientView?: Boolean;
  sessionId: string;
  speciality: string;
  modeOfConsultation: string;
  bookedDate: any;
  sessionDate: any;
  sessionMonth: any;
  startTime: any;
  endTime: any;
  timeSlots?: any;
  description: string;
  doctorImage: string;
  doctorName: string;
  doctorSpeciality: string;
  doctorExperience: string;
  patientImage: string;
  patientName: string;
  patientNumber: number;
  patientEmail: string;
  patientProfileImage: string;
  PatientProfileName: string;
  PatientProfileAge: number;
  PatientProfileGender: string;
  PatientLandmark: string;
  patientAddress1: string;
  patientAddress2: string;
  PatientCity: string;
  consultationFee: number;
  doctorFee: number;
  statusData: any;
  startSessionClick: any;
  handleReportClick: any;
  handleComplete: any;
  handlePdfChange?: any;
  generateReportClick?: any;
  handleReportsView?: any;
  joinHomeSession?: any;
  minutes?: any;
  seconds?: any;
  progress?: any;
  handleOtpClick?: any;
  otpChange?: any;
  otpValue?: any;
  otpDate?: any;
  checkOtp?: any;
}

export const SessionDetailCard = ({
  patientView,
  sessionId,
  speciality,
  modeOfConsultation,
  bookedDate,
  sessionDate,
  sessionMonth,
  startTime,
  endTime,
  timeSlots,
  description,
  patientImage,
  patientName,
  patientNumber,
  patientEmail,
  patientProfileImage,
  PatientProfileName,
  PatientProfileAge,
  PatientProfileGender,
  PatientLandmark,
  patientAddress1,
  patientAddress2,
  PatientCity,
  consultationFee,
  doctorFee,
  doctorImage,
  doctorName,
  doctorSpeciality,
  doctorExperience,
  statusData,
  startSessionClick,
  handleReportClick,
  handleComplete,
  handlePdfChange,
  generateReportClick,
  handleReportsView,
  joinHomeSession,
  minutes,
  seconds,
  progress,
  handleOtpClick,
  otpChange,
  otpValue,
  otpDate,
  checkOtp,
}: SessionProps) => {
  const theme = useTheme();
  const id = useParams();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateSession, {
    onSuccess: () => {
      queryClient.invalidateQueries("sessions");
    },
  });

  const handleReject = () => {
    const res = { status: "REJECTED" };
    mutate({ id: id?.id, body: res });
  };
  const handleApprove = () => {
    const res = { status: "APPROVED" };
    mutate({ id: id?.id, body: res });
  };

  const { data } = useQuery("clinician-profile", getClinicianProfile);

  const { mutate: clinicianMutate } = useMutation(updateClinicianSession, {
    onSuccess: () => {
      queryClient.invalidateQueries("sessions");
    },
  });

  const NewClincianAccept = () => {
    const res = { isNewClinicianAccepted: true };
    clinicianMutate({ id: statusData?.data?.sessionClinician?.id, body: res });
  };

  const NewClinicianReject = () => {
    const res = { isNewClinicianAccepted: false };
    clinicianMutate({ id: statusData?.data?.sessionClinician?.id, body: res });
  };

  if (isLoading) {
    <Loader />;
  }

  const PdfBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    padding: "8px 24px",
    borderRadius: "5px",
    border: "1px solid #DADADA",
  });
  console.log(consultationFee, "fees");

  return (
    <Box p={2} overflow="auto" maxHeight="700px" className="scrollBox">
      <Box p={2} border={"1px solid rgba(34, 34, 34, 0.08)"} borderRadius="5px">
        <Typography variant="h4">{sessionId}</Typography>
        <Grid container spacing={1.5} mt={0.5}>
          <Grid item xs={6}>
            <TitleName title="Speciality" detail={speciality} />
          </Grid>
          <Grid item xs={6}>
            <TitleName
              title="Mode of consultation"
              detail={modeOfConsultation}
            />
          </Grid>
          <Grid item xs={6}>
            <TitleName title="Booked date" detail={bookedDate} />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" gap={1} pt={1}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent={"center"}
                p={1}
                maxWidth="30px"
                alignItems="center"
                sx={{ backgroundColor: "#F0F0F0", borderRadius: "10px" }}
              >
                <Typography>{sessionDate}</Typography>
                <Typography>{sessionMonth}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="caption" sx={{ fontSize: "12px" }}>
                  Time Slot
                </Typography>
                {timeSlots?.map((item: any, index: number) => (
                  <Typography key={index}>
                    {item?.timeslot?.startAt}-{item?.timeslot?.startAt}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <TitleName title="Description" detail={description} />
        </Box>
      </Box>
      {/* <Box
        mt={2}
        pt={2}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Typography pb={2} px={2} variant="h4">
          Clinician's Details
        </Typography>
        <Divider />
        <Box display="flex" alignItems="center" p={2}>
          <Avatar
            variant="rounded"
            sx={{ height: "80px", width: "80px", fontSize: "40px" }}
            src={doctorImage}
            alt={doctorName}
          />
          <Box pl={2}>
            <Typography variant="h5">{doctorName}</Typography>
            <Typography variant="subtitle2" sx={{ color: "#1268AE" }}>
              {doctorSpeciality}
            </Typography>
            <Box
              sx={{
                backgroundColor: "#ECF3F9",
                px: "15px",
                py: "4px",
                mt: "5px",
                borderRadius: "11px",
              }}
            >
              <Typography variant="subtitle2">
                {doctorExperience} Year Exp.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box> */}
      {/* <Box
        mt={2}
        pt={2}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Typography pb={2} px={2} variant="h4">
          Client Details
        </Typography>
        <Divider />
        <Box display="flex" alignItems="center" p={2}>
          <Avatar
            variant="rounded"
            sx={{ height: "80px", width: "80px", fontSize: "40px" }}
            src={patientImage}
            alt={patientName}
          />
          <Box pl={2}>
            <Typography variant="h5">{patientName}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <img
                src={icons.mobileIcn}
                style={{ width: "20px", height: "20px" }}
              />
              <Typography>{patientNumber}</Typography>
            </Box>
            <Box display="flex" gap={1} alignItems="center">
              <img
                src={icons.emailIcn}
                style={{ width: "20px", height: "15px" }}
              />
              <Typography>{patientEmail}</Typography>
            </Box>
          </Box>
        </Box>
      </Box> */}
      <Box
        mt={2}
        pt={2}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Typography pb={2} px={2} variant="h4">
          Client Details
        </Typography>
        <Divider />
        <Box
          display="flex"
          p={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Avatar
              variant="rounded"
              sx={{ height: "80px", width: "80px", fontSize: "40px" }}
              src={patientProfileImage}
              alt={PatientProfileName}
            />
            <Box pl={2}>
              <Typography variant="h5">{PatientProfileName}</Typography>
              <Typography variant="caption" sx={{ fontSize: "12px" }}>
                {PatientProfileAge} years
              </Typography>
              <Typography variant="subtitle2">
                {PatientProfileGender}
              </Typography>
            </Box>
          </Box>
          <Box paddingRight={60}>
            <Button variant="outlined" onClick={handleReportClick}>
              Medical Records
            </Button>
          </Box>
        </Box>
      </Box>
      {statusData?.data?.consultationMode === "HOME" && (
        <Box
          mt={2}
          py={2}
          border={"1px solid rgba(34, 34, 34, 0.08)"}
          borderRadius="5px"
        >
          <Typography pb={2} px={2} variant="h4">
            Patient Location Details
          </Typography>
          <Divider />

          <Box display="flex" p={2} gap={1}>
            <PlaceOutlinedIcon />
            <Box>
              <Typography>{PatientCity}</Typography>
              <Typography>
                {patientAddress1}, {patientAddress2}, {PatientLandmark}{" "}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" px={2.4} gap={1.4} alignItems="center">
            <img src={icons.mobileIcn} />
            <Typography>{patientNumber}</Typography>
          </Box>
        </Box>
      )}
      <Box
        mt={2}
        pt={2}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Typography pb={2} px={2} variant="h4">
          Consultation Bill Details
        </Typography>
        <Divider />

        <Box display="flex" p={2} gap={1} justifyContent="space-between">
          <Typography>Doctor Fee</Typography>
          <Typography paddingRight={70}>{doctorFee} SAR</Typography>
        </Box>
        <Box display="flex" p={2} gap={1} justifyContent="space-between">
          <Typography>Consultation Fee</Typography>
          <Typography paddingRight={70}>{consultationFee} SAR</Typography>
        </Box>
        <Divider />
        <Box display="flex" p={2} gap={1} justifyContent="space-between">
          <Typography>Total Amount</Typography>
          <Typography paddingRight={70}>
            {Number(consultationFee) + Number(doctorFee)} SAR
          </Typography>
        </Box>
      </Box>

      {Boolean(joinHomeSession) && (
        <Box>
          {checkOtp === false && (
            <Box>
              <MuiOtpInput
                mx={10}
                mt={3}
                value={otpValue}
                onChange={otpChange}
                TextFieldsProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
              />
              <Button
                sx={{ mt: 3 }}
                fullWidth
                onClick={handleOtpClick}
                variant="contained"
                type="submit"
              >
                Submit OTP
              </Button>
            </Box>
          )}
          {Boolean(checkOtp) && (
            <Box mt={2} bgcolor="#F0F0F0" borderRadius="10px" px={2} py={2}>
              <FlexBox>
                <FlexBox alignItems="center" gap={1}>
                  <EventIcon />
                  <Typography>{otpDate}</Typography>
                </FlexBox>
              </FlexBox>
              <FlexBox alignItems="center" justifyContent="space-between">
                <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
                  Session's Remaining Time is
                </Typography>
                <Box color="#2E78E6" maxWidth="95px" pr={4} display="flex">
                  <Typography>{minutes}</Typography>
                  <Typography>:</Typography>
                  <Typography>{seconds}</Typography>
                </Box>
              </FlexBox>
              <Box my={1}>
                <LinearProgress
                  sx={{ height: "8px", borderRadius: "10px", color: "#2E78E6" }}
                  variant="determinate"
                  value={progress}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}

      {statusData?.data?.status === "PENDING" && (
        <>
          {patientView === false && (
            <Box pt={2} display="flex" gap={2}>
              <Button
                onClick={() => handleReject()}
                // fullWidth
                variant="contained"
                sx={{
                  width: "300px",
                  marginLeft: "400px",
                  color: theme.colors.error.main,
                  backgroundColor: theme.colors.error.lighter,
                  "&:hover": {
                    background: theme.colors.error.main,
                    color: "white",
                  },
                }}
              >
                Reject
              </Button>
              <Button
                onClick={() => handleApprove()}
                sx={{ width: "300px" }}
                // fullWidth
                variant="outlined"
              >
                Approve
              </Button>
            </Box>
          )}
        </>
      )}

      {statusData?.data?.status === "COMPLETED" ? (
        <>
          {patientView === false && (
            <>
              <Box mt={2} display="flex" maxWidth={300} paddingLeft={60}>
                <label
                  htmlFor={"report"}
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <PdfBox>
                    <FileUploadOutlinedIcon />
                    <Typography variant="body2" sx={{ fontSize: "16px" }}>
                      Upload Medical Report
                    </Typography>
                  </PdfBox>
                </label>
                <input
                  id={"report"}
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  accept=".pdf"
                  onChange={(e) => handlePdfChange(e)}
                />
              </Box>
              <Box width={700} paddingLeft={60}>
                <Button
                  onClick={generateReportClick}
                  sx={{ mt: 2, width: "300px" }}
                  variant="contained"
                  // fullWidth
                >
                  Generate Report
                </Button>
              </Box>
            </>
          )}
        </>
      ) : null}

      {statusData?.data?.status === "REPORT_SUBMITTED" && (
        <Box mt={2}>
          {patientView === false && (
            <Button
              onClick={handleReportsView}
              variant="outlined"
              sx={{ width: "300px", marginLeft: "550px" }}
            >
              View Reports
            </Button>
          )}
        </Box>
      )}

      {statusData?.data?.status === "STARTED" && (
        <>
          {patientView === false && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Button
                  onClick={startSessionClick}
                  sx={{ mt: 2, width: "160px" }}
                  variant="contained"
                >
                  Start Session
                </Button>

                <Button
                  onClick={handleComplete}
                  sx={{ mt: 2 }}
                  variant="contained"
                >
                  Complete Session
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {statusData?.data?.status === "PAID" && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={startSessionClick}
              sx={{ mt: 2 }}
              variant="contained"
            >
              Start Session
            </Button>
          </Box>
        </>
      )}

      {statusData?.data?.status === "NEW_CLINICIAN_ASSIGNED" && (
        <>
          {statusData?.data?.sessionClinician?.newClinicianId ===
            data?.data?.id && (
            <Box pt={2} display="flex" gap={2}>
              <Button
                onClick={() => NewClinicianReject()}
                fullWidth
                variant="contained"
                sx={{
                  color: theme.colors.error.main,
                  backgroundColor: theme.colors.error.lighter,
                  "&:hover": {
                    background: theme.colors.error.main,
                    color: "white",
                  },
                }}
              >
                Reject
              </Button>
              <Button
                onClick={() => NewClincianAccept()}
                fullWidth
                variant="outlined"
              >
                Approve
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

let FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const TitleName = ({ title, detail }: any) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="caption" sx={{ fontSize: "12px" }}>
        {title}
      </Typography>
      <Typography>{detail}</Typography>
    </Box>
  );
};
