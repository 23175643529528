import { AppBar, Box, Typography } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";

export const Profile = () => {
  return (
    <div>
      <AppBar
        style={{
          // position: 'absolute',
          top: 90,
          left: "350px",
          backgroundColor: "#F4F4F4",
          boxShadow: "none",
        }}
      >
        <nav
          style={{
            display: "flex",
            paddingLeft: "38px",
          }}
        >
          <NavLink className={"resourcesNavLink"} to={"/profile"} end>
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Basic Information
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={"resourcesNavLink"}
            to={"/profile/session-package"}
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Session Package Details
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={"resourcesNavLink"}
            to={"/profile/payment-details"}
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Payment Details
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={"resourcesNavLink"}
            to={"/profile/slot-management"}
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Slot Management
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
        </nav>
      </AppBar>
      <Box sx={{ marginTop: "50px" }}>
        <Outlet />
      </Box>
    </div>
  );
};
