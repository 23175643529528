import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getClinicianProfile, getSessionData } from "../../../api/services/api";
import AnalyticsCard from "../../../components/AnalyticsCard";

export const Session = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { data } = useQuery(["clinician-profile"], getClinicianProfile);

  const { data: dashboardData } = useQuery(
    ["session-data", data?.data?.id],
    getSessionData,
    {
      enabled: !!data?.data?.id,
    }
  );

  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() => navigate("/sessions/upcoming-sessions")}
          >
            <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
              <Typography>Upcoming Sessions</Typography>
            </Box>
            <AnalyticsCard
              title="Total Sessions"
              number={dashboardData?.data?.sessionDetails?.upcoming || 0}
              AvatarColor={theme.colors.warning.main}
              bg={theme.colors.warning.lighter}
              ChartColor={theme.colors.warning.main}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() => navigate("/sessions/completed-sessions")}
          >
            <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
              <Typography>Completed Sessions</Typography>
            </Box>
            <AnalyticsCard
              title="Total Sessions"
              number={dashboardData?.data?.sessionDetails?.completed || 0}
              AvatarColor={theme.colors.primary.main}
              bg={theme.colors.primary.lighter}
              ChartColor={theme.colors.primary.main}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() => navigate("/sessions/cancelled-sessions")}
          >
            <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
              <Typography>Cancelled Sessions</Typography>
            </Box>
            <AnalyticsCard
              title="Total Sessions"
              number={dashboardData?.data?.sessionDetails?.rejected || 0}
              AvatarColor={theme.colors.error.main}
              bg={theme.colors.error.lighter}
              ChartColor={theme.colors.error.main}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
