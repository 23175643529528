import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SummarizeIcon from "@mui/icons-material/Summarize";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  ListItem,
  Rating,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  Cell,
  Tooltip as NewTip,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";

import { useNavigate } from "react-router-dom";
import {
  getClinicianProfile,
  getDashboardData,
  getSessions,
} from "../../../api/services/api";
import AnalyticsCard from "../../../components/AnalyticsCard";
import ColorTags from "../../../components/ColorTags";
import Loader from "../../../components/Loader";
import textCapitalization from "../../../components/textCapitalization";
import { AvatarPrimary, BoxItemWrapper, DotLegend, TitleText } from "./style";

function TotalAnalytics() {
  const theme = useTheme();
  const [status, setStatus] = useState(["PENDING", "APPROVED", "PAID"]);

  const { data, isLoading } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );
  const navigate = useNavigate();
  const clinicianId = data?.data?.id;

  const { data: dashboardData, isLoading: DashboadLoading } = useQuery(
    ["dashboard-data", data?.data?.id],
    getDashboardData,
    {
      enabled: Boolean(data?.data?.id),
    }
  );

  const { data: sessionData } = useQuery(
    [
      "sessions",
      {
        status,
        clinicianId,
      },
    ],
    getSessions
  );

  const { data: allSessionData } = useQuery(
    [
      "sessions",
      {
        clinicianId,
      },
    ],
    getSessions
  );

  console.log(allSessionData, "session");

  const COLORS = ["#FFA319", "#57CA22", "#0052CC"];

  const customStyles = {
    background1: "rgba(128, 128, 128, 0.6)",
    background2: "rgba(204, 119, 34, 0.6)",
    borderRadius: "18px 18px 0px 0px",
    opacity: 0.1,
  };

  const TypeColor = [
    "#FF3465",
    "#684ED5",
    "#57CA22",
    "#FFA319",
    "#D58E4E",
    "#032961",
  ];

  const currentDate = new Date();
  const currentMonth = currentDate?.toISOString()?.slice(0, 7);

  const previousMonthDate = new Date(currentDate);
  previousMonthDate.setMonth(currentDate?.getMonth() - 1);
  const previousMonth = previousMonthDate?.toISOString()?.slice(0, 7);

  const currentMonthSessions = allSessionData?.data?.sessions?.filter(
    (f: any) => {
      const sessionMonth = f?.date?.slice(0, 7);
      return sessionMonth === currentMonth;
    }
  );

  const previousMonthSessions = allSessionData?.data?.sessions?.filter(
    (f: any) => {
      const sessionMonth = f?.date?.slice(0, 7);
      return sessionMonth === previousMonth;
    }
  );

  const numberOfSessionsPercentageChange = calculateSessionPercentageChange(
    currentMonthSessions,
    previousMonthSessions
  );
  const numberOfPaymentsPercentageChange = calculatePaymentPercentageChange(
    currentMonthSessions,
    previousMonthSessions
  );
  const totalClientsPercentageChange = calculateClientPercentageChange(
    currentMonthSessions,
    previousMonthSessions
  );

  if (isLoading || DashboadLoading) {
    return <Loader />;
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography variant="h4" sx={{ mb: 2, mt: 1 }}>
            Total Analytics
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <Card
                onClick={() => navigate("/sessions")}
                style={{ height: "102%" }} // Adjust the height as needed
              >
                <AnalyticsCard
                  title="Number of Sessions"
                  icon={<WatchLaterIcon />}
                  number={dashboardData?.data?.sessionDetails?.total || 0}
                  AvatarColor={theme.colors.primary.main}
                  bg={theme.colors.primary.lighter}
                  ChartColor={customStyles.background1}
                  style={customStyles}
                  allSessionData={numberOfSessionsPercentageChange}
                />
              </Card>
            </Grid>
            <Grid item lg={4} md={6}>
              <Card
                onClick={() => navigate("/payments")}
                style={{ height: "102%" }} // Adjust the height as needed
              >
                <AnalyticsCard
                  title="Number of Payments"
                  icon={<SummarizeIcon sx={{ filter: "brightness(0.7)" }} />}
                  number={dashboardData?.data?.sessionDetails?.paid || 0}
                  AvatarColor={theme.colors.warning.main}
                  bg={theme.colors.warning.lighter}
                  ChartColor={customStyles.background2}
                  style={customStyles}
                  allSessionData={numberOfPaymentsPercentageChange}
                />
              </Card>
            </Grid>
            <Grid item lg={4} md={6}>
              <Card
                onClick={() => navigate("/patients")}
                style={{ height: "102%" }} // Adjust the height as needed
              >
                <AnalyticsCard
                  title="Total Clients"
                  icon={<PersonAddIcon />}
                  number={dashboardData?.data?.totalPatients || 0}
                  AvatarColor={theme.colors.success.main}
                  bg={theme.colors.success.lighter}
                  ChartColor={customStyles.background1}
                  style={customStyles}
                  allSessionData={totalClientsPercentageChange}
                />
              </Card>
            </Grid>
          </Grid>

          <Card sx={{ width: "100%", marginTop: "20px", marginBottom: "80px" }}>
            <TitleText>Upcoming Sessions</TitleText>
            <Divider />
            {sessionData?.data?.count === 0 ? (
              <Typography variant="h5" sx={{ p: 2 }}>
                No Records Found...
              </Typography>
            ) : (
              <>
                {sessionData?.data?.sessions?.slice(0, 5)?.map((item: any) => (
                  <ListItem
                    component="div"
                    sx={{
                      pt: 2,
                    }}
                  >
                    <BoxItemWrapper
                      sx={{
                        "::before": {
                          background:
                            item?.status === "PENDING"
                              ? `${theme.colors.warning.main}`
                              : item?.status === "APPROVED"
                              ? `${theme.colors.success.main}`
                              : item?.status === "COMPLETED"
                              ? `${theme.colors.primary.main}`
                              : item?.status === "CANCELLED"
                              ? `${theme.colors.error.main}`
                              : "",
                          color:
                            item?.status === "PENDING"
                              ? `${theme.colors.warning.main}`
                              : item?.status === "APPROVED"
                              ? `${theme.colors.success.main}`
                              : item?.status === "COMPLETED"
                              ? `${theme.colors.primary.main}`
                              : item?.status === "CANCELLED"
                              ? `${theme.colors.error.main}`
                              : "",
                        },
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mr: 2,
                        }}
                      >
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            item?.status === "PENDING"
                              ? "Appointment is Pending"
                              : item?.status === "APPROVED"
                              ? "Appointment has Approved"
                              : item?.status === "COMPLETED"
                              ? "Appointment has Completed"
                              : item?.status === "CANCELLED"
                              ? "Appointment has Cancelled"
                              : ""
                          }
                        >
                          <DotLegend
                            style={{
                              background:
                                item?.status === "PENDING"
                                  ? `${theme.colors.warning.main}`
                                  : item?.status === "APPROVED"
                                  ? `${theme.colors.success.main}`
                                  : item?.status === "COMPLETED"
                                  ? `${theme.colors.primary.main}`
                                  : item?.status === "CANCELLED"
                                  ? `${theme.colors.error.main}`
                                  : "",
                            }}
                          />
                        </Tooltip>

                        <Typography
                          component="span"
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {item?.patient?.user?.fullName}
                        </Typography>
                        <Button
                          style={{
                            position: "absolute",
                            height: "35px",
                            maxWidth: "120px",
                            right: "30px",
                            top: "10px",
                            background:
                              item?.status === "PENDING"
                                ? `${theme.colors.warning.lighter}`
                                : item?.status === "APPROVED"
                                ? `${theme.colors.success.lighter}`
                                : item?.status === "PAID"
                                ? `${theme.colors.primary.lighter}`
                                : "",
                            color:
                              item?.status === "PENDING"
                                ? `${theme.colors.warning.main}`
                                : item?.status === "APPROVED"
                                ? `${theme.colors.success.main}`
                                : item?.status === "PAID"
                                ? `${theme.colors.primary.main}`
                                : "",
                          }}
                        >
                          {item?.status?.replace(/_/g, " ")}
                          {/* {item?status?.replace(/_/g, " ")} */}
                        </Button>
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          pl: 2.3,
                          py: 1,
                        }}
                      >
                        {moment(item?.date).format("DD-MMMM-YYYY")},&nbsp;
                        <b>
                          {moment(item?.startAt, "HH:mm").format("h:mm A")}-
                          {moment(item?.endAt, "HH:mm").format("h:mm A")}
                        </b>
                      </Typography>
                      <Box
                        sx={{
                          pl: 2.3,
                        }}
                      >
                        {item?.symptom && (
                          <Chip
                            variant="outlined"
                            sx={{
                              mr: 0.5,
                            }}
                            size="small"
                            label={item?.symptom}
                            color="primary"
                          />
                        )}
                        <Chip
                          variant="outlined"
                          sx={{
                            mr: 0.5,
                          }}
                          size="small"
                          label={textCapitalization(item?.consultationMode)}
                          color="primary"
                        />
                      </Box>
                    </BoxItemWrapper>
                  </ListItem>
                ))}
              </>
            )}
          </Card>
        </Grid>
        <Grid item xs={3}>
          {/* <Card
            sx={{
              padding: "40px 30px 20px 30px",
            }}
          >
            <Box sx={{ textAlign: "center", margin: "auto", width: "50%" }}>
              <Avatar
                variant="rounded"
                sx={{ height: "142px", width: "142px", fontSize: "90px" }}
                src={data?.data?.imageUrl}
                alt={data?.data?.user?.fullName}
              />
              <Typography variant="h3" m="15px 0 10px 0">
                {data?.data?.user?.fullName}
              </Typography>
              <Typography variant="caption">{data?.data?.location}</Typography>
            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box py="20px" display="flex" flexDirection="column" gap={1.5}>
              <ProfileBox>
                <img src={icons.graduateIcn} alt="graduate" height="16px" />
                <Typography ml={1} noWrap>
                  {data?.data?.designation}
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.mobileIcn} alt="mobile" height="16px" />
                <Typography ml={1} noWrap>
                  {data?.data?.user?.mobileNumber}
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.emailIcn} alt="email" height="16px" />
                <Typography ml={1} noWrap>
                  {data?.data?.user?.email}
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.experienceIcn} alt="experience" height="16px" />
                <Typography ml={1}>
                  {data?.data?.experience} Year Experience
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.dobIcn} alt="dob" height="16px" />
                <Typography ml={1}>
                  {moment(data?.data?.dob).format("DD-MM-YYYY")}
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.genderIcn} alt="gender" height="16px" />
                <Typography ml={1}>
                  {textCapitalization(data?.data?.gender || "")}
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.language} alt="gender" height="16px" />
                <Typography ml={1}>{data?.data?.languagesKnown}</Typography>
              </ProfileBox>
              <ProfileBox gap={1}>
                <img src={icons.serviceIcon} alt="gender" height="16px" />
                {data?.data?.services?.map((items: any, index: number) => (
                  <Box display="flex" key={index}>
                    <Typography>{items?.title}</Typography>
                    {data?.data?.services?.length !== index + 1 && (
                      <Typography>,</Typography>
                    )}
                  </Box>
                ))}
              </ProfileBox>
            </Box>
          </Card> */}
          <Card sx={{ padding: "28px 20px 28px 20px", marginTop: "35px" }}>
            <Typography>Overall Rating</Typography>
            <Typography mt={3} variant="h1">
              {Math.round(dashboardData?.data?.rating?.averageRating * 10) / 10}
            </Typography>
            <Rating
              readOnly
              sx={{ margin: "15px 0 20px 0" }}
              value={dashboardData?.data?.rating?.averageRating}
              precision={0.5}
            />
            <Divider />
            <Typography m="28px 0 24px 0">Total Reviews</Typography>
            <Typography variant="h1">
              {dashboardData?.data?.rating?.totalReviews}
            </Typography>
          </Card>
          <Card sx={{ padding: "28px 20px 28px 20px", marginTop: "10px" }}>
            <AvatarPrimary>
              <WatchLaterIcon />
            </AvatarPrimary>
            <Typography mt={3}>Total Reports</Typography>
            <Typography mt={2} variant="h1">
              {dashboardData?.data?.totalReports}
            </Typography>
          </Card>
          <Card
            sx={{
              padding: "8px 2px 2px 20px",
              paddingBottom: "100px",
              marginTop: "10px",
              // marginBottom: "10px",
            }}
          >
            <Typography variant="h4" sx={{ mb: 2, mt: 1 }}>
              Session Analytics
            </Typography>
            <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
              <Grid item lg={12}>
                <Card sx={{ width: "95%", height: "100%" }}>
                  <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
                    <Typography>Sessions by Mode</Typography>
                  </Box>
                  <Box
                    width="100%"
                    height="85%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {dashboardData?.data?.sessionModes?.every(
                      (item: any) => item.count === 0
                    ) ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="90%"
                        width="60%"
                      >
                        <Typography my={11} variant="h4">
                          No Data Available...
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={dashboardData?.data?.sessionModes}
                              dataKey="count"
                              nameKey="mode"
                              cx="50%"
                              cy="50%"
                              innerRadius={90}
                              outerRadius={110}
                              fill="color"
                            >
                              {dashboardData?.data?.sessionModes.map(
                                (entry: any, index: any) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                )
                              )}
                            </Pie>
                            <NewTip />
                          </PieChart>
                        </ResponsiveContainer>
                      </>
                    )}
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={1.5}
                      justifyContent="center"
                      mt={2}
                      width="50%"
                      marginBottom="10%"
                    >
                      <ColorTags color="#57CA22" tag="Video" />
                      <ColorTags color="#FFA319" tag="Audio" />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default TotalAnalytics;

const calculateSessionPercentageChange = (
  currentMonthSessions: any,
  previousMonthSessions: any
) => {
  const currentSessionCount = currentMonthSessions?.length;
  const previousSessionCount = previousMonthSessions?.length;

  if (previousSessionCount === 0) {
    return currentSessionCount > 0 ? "100%" : "0%";
  }

  const sessionChangePercentage =
    ((currentSessionCount - previousSessionCount) / previousSessionCount) * 100;
  return sessionChangePercentage?.toFixed(2) + "%";
};

const calculatePaymentPercentageChange = (
  currentMonthSessions: any,
  previousMonthSessions: any
) => {
  const currentPaymentCount = currentMonthSessions?.filter(
    (session: any) => session.sessionPayment?.paymentStatus === true
  ).length;

  const previousPaymentCount = previousMonthSessions?.filter(
    (session: any) => session.sessionPayment?.paymentStatus === true
  ).length;

  if (previousPaymentCount === 0) {
    return currentPaymentCount > 0 ? "100%" : "0%";
  }

  const paymentChangePercentage =
    ((currentPaymentCount - previousPaymentCount) / previousPaymentCount) * 100;
  return paymentChangePercentage?.toFixed(2) + "%";
};

const calculateClientPercentageChange = (
  currentMonthSessions: any,
  previousMonthSessions: any
) => {
  const currentUniqueClients = new Set(
    currentMonthSessions?.map((session: any) => session?.patientId)
  ).size;

  const previousUniqueClients = new Set(
    previousMonthSessions?.map((session: any) => session?.patientId)
  ).size;

  if (previousUniqueClients === 0) {
    return currentUniqueClients > 0 ? "100%" : "0%";
  }

  const clientChangePercentage =
    ((currentUniqueClients - previousUniqueClients) / previousUniqueClients) *
    100;
  return clientChangePercentage?.toFixed(2) + "%";
};
