import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SearchIcon from "@mui/icons-material/Search";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getClinicianProfile, getPatients } from "../../../api/services/api";
import EmptyScreen from "../../../components/EmptyScreen";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import textCapitalization from "../../../components/textCapitalization";

const Patients = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [gender, setGender] = useState<string>("");
  const [speciality, setSpeciality] = useState("");
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [patientId, setPatientId] = useState(0);
  const [age, setAge] = useState<string>();
  const [newanchorEl, setNewAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const openDialog = Boolean(newanchorEl);

  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl(event.currentTarget);
    setPatientId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setNewAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setNewAnchorEl(null);
  };

  const { data: profileData } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );

  const { data, isLoading } = useQuery(
    [
      "patients",
      profileData?.data?.id,
      {
        gender: gender,
        designation: speciality,
        search: search,
        limit: limit,
        offset: limit * page,
        age: age,
      },
    ],
    getPatients,
    {
      enabled: !!profileData?.data?.id,
    }
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },

    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },

    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (colData: any, row: any) => {
        return (
          <Box>
            <Button onClick={(event) => handleClick(event, row?.id)}>
              <MoreVertIcon />
            </Button>
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() =>
                  navigate(`/patients/patient-profile/${patientId}`)
                }
              >
                <PermIdentityIcon />
                <Typography sx={{ pl: 1.5 }}>View Profile</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() =>
                  navigate(`/patients/patient-session/${patientId}`)
                }
              >
                <WatchLaterOutlinedIcon />
                <Typography sx={{ pl: 1.5 }}>View Sessions</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  navigate(`/reports/${patientId}`);
                }}
              >
                <TodayOutlinedIcon />
                <Typography sx={{ pl: 1.5 }}>View School Reports</Typography>
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  const genderData = ["MALE", "FEMALE", "OTHERS"];

  const ageData = ["1-10", "11-20", "21-40", "41-60", ">60"];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          mt: "20px",
        }}
      >
        <Box sx={{ display: "flex" }} gap={"26px"}>
          <Typography variant="h3">Clients</Typography>
        </Box>
        <Box display="flex" gap="20px">
          <TextField
            label="Search by Name"
            sx={{ width: "450px", padding: "0px" }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button
            variant="outlined"
            startIcon={<FilterListOutlinedIcon />}
            onClick={handleFilterClick}
          >
            <Typography>Filter</Typography>
          </Button>
          <Menu
            open={openDialog}
            onClose={handleFilterClose}
            anchorEl={newanchorEl}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem sx={{ width: "350px" }}>
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  options={genderData}
                  value={gender}
                  getOptionLabel={(option) => textCapitalization(option || "")}
                  onChange={(event, newValue) => {
                    setGender(newValue || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Select Gender"
                    />
                  )}
                />
              </Box>
            </MenuItem>
            <MenuItem>
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  options={ageData}
                  getOptionLabel={(option) => option}
                  value={age}
                  onChange={(event, newValue) => {
                    setAge(newValue?.split(">").join("") || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Select Age"
                    />
                  )}
                />
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {data?.data?.patients?.length == 0 ? (
        <EmptyScreen type={"Patients"} />
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              key={"patients"}
              dataSource={data?.data?.patients}
              columns={columns}
              loading={isLoading}
            />
          )}
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TablePagination
              count={data?.data.count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Patients;
